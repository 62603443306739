import React, { useEffect, useState } from "react"
import ReactPlayer from 'react-player'
import axios from "axios"

const VideoPlayer = ({ nVideos, singleVideoId, downloadButton }) => {
    const [urls, setUrls] = useState([])
    const [count, setCount] = useState(0)
    const [isLoadingVideos, setIsLoadingVideos] = useState(false)
    const [isLoadingUrl, setIsLoadingUrl] = useState(false)

    const getSignedUrl = (id) => {
        setIsLoadingUrl(true)
        axios.get(`https://lunarrainbow.co/getpresignedurl/${id}`)
        .then((response) => {
            if (response.data.data !== undefined) {
                setUrls(prevUrls => [...prevUrls, response.data.data])
                setIsLoadingUrl(false)
            }
        })
        .catch(e => {
            console.error(e)
        })
    }

    const getVideos = (vidNo) => {
        setIsLoadingVideos(true)
        axios.get(`https://lunarrainbow.co/videos/${vidNo}`)
        .then((response) => {
            response.data.data.forEach(video => {
                // console.log(video)
                getSignedUrl(video.vidid)
            })
            setIsLoadingVideos(false)
        })
        .catch(e => {
            console.error(e)
        })
    }

    useEffect(() => {
        if (nVideos !== undefined) {
            getVideos(nVideos)
        }
    }, [nVideos])

    useEffect(() => {
        if (singleVideoId !== undefined) {
            getSignedUrl(singleVideoId)
        }
    }, [singleVideoId])

    const playNextVideo = () => {
        if (count === urls.length - 1) {
            setCount(0)
        } else {
            setCount(count + 1)
        }
    }

    // Interval example:
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         // Your logic here
    //         console.log("This will run every 5 seconds");
    //     }, 5000); // 5000 milliseconds = 5 seconds

    //     // Clear the interval when the component unmounts
    //     return () => clearInterval(intervalId);
    // }, []);  // Empty dependency array means this useEffect runs once when component mounts and cleans up when it unmounts

    return (
        <>
            {isLoadingUrl || isLoadingVideos ? (
                <p>Loading...</p>
            ) : (
                <ReactPlayer
                    className="p-4"
                    url={urls[count]}
                    controls
                    width={(1072 / 1440) * window.innerHeight}
                    height={window.innerHeight * 0.85}
                    playing
                    muted={true} // necessary for autoplay in browsers (CHrome)
                    onEnded={playNextVideo}
                />
            )}
            {downloadButton && (
                <a href={isLoadingUrl ? "" : urls[count]} download>
                    <button className="bg-jbl-blue text-white font-bold py-2 px-4 rounded-full">
                        Download
                    </button>
                </a>
            )}
        </>
    )
}

export default VideoPlayer
