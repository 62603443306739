import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const VideoCard = ( { video, counter, w, h } ) => {
  const qrcode = `https://jblquantum.s3.eu-central-1.amazonaws.com/${video.vidid}_pagelink.png`

  return (
      <>
        <div className="flex justify-center items-end m-5 gap-5 p-5 bg-white bg-opacity-10">
            <div>
              <LazyLoadImage src={ `https://jblquantum.s3.eu-central-1.amazonaws.com/${video.vidid}_thumbnail.jpeg` }
                width={ w } height={ h }
                alt="JBL Quantum 360 Video"
              />
              {/* <img width={ w } height={ h } src={ `https://jblquantum.s3.eu-central-1.amazonaws.com/${video.vidid}_thumbnail.jpeg` } /> */}
            </div>
            <div>
              <p className="text-gray-700 text-center">{ video.vidid } <br /> No. { counter }</p>
              {/* <img className="w-32 mt-5 p-2 bg-white" src={ qrcode } /> */}
              <LazyLoadImage src={ qrcode }
                className="w-32 mt-5 p-2 bg-white"
                width={ w } height={ h }
                alt="JBL Quantum 360 Video"
              />
            </div>
        </div>
      </>
  )
}

export default VideoCard