import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

import Home from './pages/index.jsx'
import Gallery from './pages/gallery.jsx'
import VideoPage from './pages/videoPage.jsx'
import VideoLoop from './pages/videoLoop.jsx'
import Maintenance from './pages/maintenance.jsx'

import './App.css'

const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path='/' exact element={<Home />} /> */}
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/video/:vidid' element={<VideoPage />} />
        <Route path='/videoloop/:numberOfVideos' element={<VideoLoop />} />
        <Route path='/' element={<Maintenance />} />
      </Routes>
    </Router>
  )
}

export default App