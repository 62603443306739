import React from 'react'
import { useParams } from "react-router-dom"
import { useEffect, useState, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

import VideoPlayer from '../components/VideoPlayer'

import '../index.css'

const VideoLoop = () => {
    const [videoData, setVideoData] = useState([{},{}])
    const [listening, setListening] = useState(false)
    const [loading, setLoading] = useState(true)

    let { numberOfVideos } = useParams()

    useEffect(() => {
        // const events = new EventSource("http://localhost:3000/events")
        const events = new EventSource("https://lunarrainbow.co/events")
        if (!listening) {
            events.onmessage = (event) => {
            // console.log("======== Event ======\n", event)
            const parsedData = JSON.parse(event.data)
            // console.log("========= parsedData =====\n", parsedData)
            if ( parsedData ) {
                // console.log("Getting videos data for:", vidNo)
                setVideoData(parsedData)
                }
            }
            setListening(true)
        }
    }, [ listening, videoData ])

    // console.log(signedUrls)

    return <>
        <div className="bg-image">
            <div>
                <div className="flex flex-col">
                    <div className="text-left font-bold px-6 flex gap-8 ml-5 mt-5 filter items-center">
                        <img className="h-32" src="./assets/images/JBL-Q-360@2x.png"></img>
                        <p className="ml-12 text-7xl">TAG <span className="text-jbl-blue">& WIN</span></p>
                    </div>
                </div>
            </div>
            <div className="flex justify-left ml-4">
                {
                   <VideoPlayer nVideos={ numberOfVideos } downloadButton={ false } />
                }
                <div className="flex flex-col p-6 text-left">
                    <div className="flex flex-col justify-start mb-10 mt-28">
                        <div className="px-6 text-3xl font-bold flex items-center mb-4">
                            <span className="w-16 h-16 bg-jbl-blue rounded-full flex justify-center items-center mr-4">
                                <span>1</span>
                            </span>
                            <span className="text-2xl"><span className="text-jbl-blue">SHARE</span> YOUR VIDEO <br />ON YOUR SOCIALS</span>
                        </div>
                        <div className="px-6 text-3xl font-bold flex items-center mb-4">
                            <span className="w-16 h-16 bg-jbl-blue rounded-full flex justify-center items-center mr-4">
                                <span>2</span>
                            </span>
                            <ul className="flex flex-col items-start gap-2 text-2xl">
                                    <li><FontAwesomeIcon size="lg" icon={['fab', 'instagram']} /><span className="ml-2 text-jbl-blue">TAG</span> <span>jblaudio_nl</span></li>
                                    <li><FontAwesomeIcon size="lg" icon={['fab', 'tiktok']} /><span className="ml-2 text-jbl-blue">TAG</span> <span>@jbleurope</span></li>
                            </ul>
                        </div>
                        <div className="px-6 text-3xl font-bold flex items-center">
                            <span className="w-16 h-16 bg-jbl-blue rounded-full flex justify-center items-center mr-4">
                                <span>3</span>
                            </span>
                            <span className="text-2xl">
                                WIN A<br /><span className="text-jbl-blue">JBL QUANTUM GAMING HEADSET</span>!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default VideoLoop