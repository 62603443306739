import React from 'react'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './index.css'

const Maintenance = () => {
    return (
    <>
    <div className="bg-image">
        <div>
            <div className="flex items-center justify-center min-h-screen w-full">
                <p className="font-bold text-7xl">WE'RE <span className="text-jbl-blue">CLOSED</span></p>
            </div>
        </div>
    </div>
    </>
    )
}

export default Maintenance