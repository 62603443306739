import React from "react"
import { useState, useEffect } from 'react'

import VideoCard from '../components/VideoCard.jsx'

const Gallery = () => {
    const [videos, setVideos] = useState()
    let counter = 0

    const getVideos = async () => {
        await fetch("https://lunarrainbow.co/videos")
        .then(response => {
            return response.json()
        }).then(data => {
            reverseArray(data['data'])
            // setVideos(data['data'])
            setVideos(reverseArray(data['data']))
        })
    }

    useEffect(() => {
        getVideos()
    }, [])

    const reverseArray = (array) => {
        var newArray = []
        for (var i = array.length - 1; i >= 0; i--) {
            newArray.push(array[i])
        }
        return newArray
    }

    if(videos) {
        counter = videos.length + 1
    }

    return (
        <div className="bg-color">
            <p className="p-6 flex gap-8 ml-5 mt-5 justify-center">
                <img className="h-32" src="./assets/images/JBL-Q-360@2x.png"></img>
            </p>
            <div>
                {videos && videos.length > 0 && (
                    <div className="grid grid-cols-3 ml-10 mr-10 mt-10">
                        {videos.map(
                            video => (
                                <div key={ video.id }>
                                    <VideoCard video={ video } counter={ counter -= 1 } h={ 180 } w={ 360 } />
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Gallery