import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom"

import VideoPlayer from '../components/VideoPlayer'

import './videoPage.css'

const VideoPage = () => {
    let { vidid } = useParams()

    return (
    <>
        <div className="bg-image">
            <div className="flex justify-center items-center content-center flex-col mt-5">
                {
                    <VideoPlayer singleVideoId={ vidid } downloadButton={ true } />
                }
            </div>
        </div>
    </>
    )
}

export default VideoPage